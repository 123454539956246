import { useMetaTags } from '@lno/core/hooks/useMetaTags'
import Head from 'next/head'

export const HomeHead = () => {
  const { ogImage, title, description } = useMetaTags()
  const url = 'https://www.serasa.com.br/limpa-nome-online/'

  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:url" content={url} />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta
        property="og:image"
        content={ogImage || '/static/og/limpa-nome/thumb.jpg'}
      />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=5, viewport-fit=cover"
      />
      <link rel="canonical" href={url} />
      <meta name="robots" content="index, follow" />
    </Head>
  )
}
