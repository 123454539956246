import type { Campaign, Partners } from '@lno/core/models/Campaign'
import type { LnoContent } from '@lno/core/models/LnoContent'
import { usePageViewEffect } from '@lno/modules/analytics'

import { CampaignProvider } from '../providers/CampaignProvider'
import { CmsProvider } from '../providers/CmsProvider'

import { HomeHead } from '../components/HomeHead'
import { EVENTS_GA } from '../constants'

import HomeLayout from '../layouts'

interface HomeContainerProps {
  campaign: Campaign | null
  partners: Partners | null
  cmsContent: LnoContent | null
}

export function HomeContainer({
  campaign,
  partners,
  cmsContent,
}: HomeContainerProps) {
  usePageViewEffect(EVENTS_GA.PAGE_VIEW)

  return (
    <CmsProvider content={cmsContent}>
      <CampaignProvider campaign={campaign} partners={partners}>
        <HomeHead />
        <HomeLayout />
      </CampaignProvider>
    </CmsProvider>
  )
}

export default HomeContainer
