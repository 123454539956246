import { useContext } from 'react'
import { useCampaign } from '../useCampaign'
import { CmsContext } from '@lno/screens/Home/providers/CmsProvider'
import { getPageSettings } from '@lno/screens/Home/constants'

export const useMetaTags = () => {
  const { campaign, isActiveCampaign } = useCampaign()
  const { metaTags } = useContext(CmsContext)

  if (isActiveCampaign || !metaTags) {
    const stage = isActiveCampaign ? campaign?.data?.stage : undefined
    const { title, description } = getPageSettings(stage)
    return {
      title,
      description,
      ogImage: campaign?.data?.ogImage,
    }
  }

  return { ...metaTags }
}
